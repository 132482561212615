<template>
    <div class="body">
      <p class="corperations">中影二级市场综合服务平台</p>
      <div class="container">
        <h1>版权方平台管理系统</h1>
            <form @submit.prevent="loginSubmit">
                <div class="form-control">
                    <input type="text" v-model="username" required>
                    <label>请输入账号</label>
                    <!-- <label>
                      <span style="transition-delay: 0ms">E</span>
                        <span style="transition-delay: 50ms">m</span>
                        <span style="transition-delay: 100ms">a</span>
                        <span style="transition-delay: 150ms">i</span>
                        <span style="transition-delay: 200ms">l</span>
                  </label> -->
                </div>

                <div class="form-control">
                    <input type="password" v-model="password" required>
                    <label>请输入密码</label>
                </div>

                <button class="btn">登陆</button>

<!--                <p class="text">Don't have an account? <a href="#">Register</a> </p>-->
            </form>
        </div>
    </div>
</template>

<script>
    import qs from 'qs'
    export default {
        name: 'IssuerLogin',
        mounted() {
            const labels = document.querySelectorAll('.form-control label')
            labels.forEach(label => {
                label.innerHTML = label.innerText
                    .split('')
                    .map((letter, idx) => `<span style="transition-delay:${idx * 50}ms">${letter}</span>`)
                    .join('')
            })
        },
        data(){
            return {
                username: '',
                password: '',
            }
        },
        methods:{
            loginSubmit() {
              this.$axios.post("/login",{
                name: this.username.trim(),
                password: this.password.trim(),
                orgType: 4,
              }).then(res=>{
                    const jwt = res.headers['authorization'];
                    if(jwt){
                        localStorage.setItem("dscsToken", jwt);
                        let redirect = this.$route.query.redirect;
                        if(!redirect) redirect = '/';
                        this.$router.push({path: redirect});
                    }
                });
            }
        }
    }

</script>

<style>
    * {
        box-sizing: border-box;
    }

    .body {
        background-color: steelblue;
        color: #fff;
        font-family: 'Muli', sans-serif;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100vh;
        overflow: hidden;
        margin: 0;
    }

    .container {
        background-color: rgba(0, 0, 0, 0.4);
        padding: 20px 40px;
        border-radius: 5px;
    }

    .container h1 {
        text-align: center;
        margin-bottom: 30px;
    }

    .container a {
        text-decoration: none;
        color: lightblue;
    }

    .btn {
        cursor: pointer;
        display: inline-block;
        width: 100%;
        background: lightblue;
        padding: 15px;
        font-family: inherit;
        font-size: 16px;
        border: 0;
        border-radius: 5px;
    }

    .btn:focus {
        outline: 0;
    }

    .btn:active {
        transform: scale(0.98);
    }

    .text {
        margin-top: 30px;
    }

    .form-control {
        position: relative;
        margin: 20px 0 40px;
        width: 300px;
    }

    .form-control input {
        background-color: transparent;
        border: 0;
        border-bottom: 2px #fff solid;
        display: block;
        width: 100%;
        padding: 15px 0;
        font-size: 18px;
        color: #fff;
    }

    .form-control input:focus,
    .form-control input:valid {
        outline: 0;
        border-bottom-color: lightblue;
    }

    .form-control label {
        position: absolute;
        top: 15px;
        left: 0;
        pointer-events: none;
    }

    .form-control label span {
        display: inline-block;
        font-size: 18px;
        min-width: 5px;
        transition: 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }

    .form-control input:focus + label span,
    .form-control input:valid + label span {
        color: lightblue;
        transform: translateY(-30px);
    }

    .corperations{
      font-size: 350%;
      font-weight: bolder;
      font-family:'Microsoft Yahei';
      margin-top:-100px;
    }

</style>